import React, { useEffect, useRef, } from 'react';

import { Link } from 'gatsby';

function Avatar() {
  return (
    <Link to="/" aria-label="Home">
      <img
        src="/images/head.jpg"
        alt="Avatar"
        className="rounded-full"
        placeholder="blurred"
        layout="fixed"
        style={{ width: 64, height: 64 }}
      />
    </Link>
  );
}

function Header() {
  const headerRef = useRef(null);
  const avatarRef = useRef(null);

  useEffect(() => {
  }, []);

  return (
    <header
      className="mx-auto max-w-2xl lg:max-w-5xl px-4 md:px-0"
      ref={headerRef}
    >
      <div ref={avatarRef} className="mt-6">
        <Avatar />
      </div>
    </header>
  );
}

export default Header;
