// import { Footer } from '@/components/Footer'; // Uncomment if needed.
import '@/styles/tailwind.css';
import 'focus-visible';

import Header from '@/components/Header';
import { Helmet } from "react-helmet";
import Pre from '@/components/Pre';
import React from 'react';

const components = {
  pre: Pre,
};

const Layout = ({ children, location }) => {
  return (
    <>
    <Helmet>
      <title>Mike Stanescu - Developer and Engineer</title>
      <meta name="description" content="Official site of Mike Stanescu, developer and engineer." />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content="https://www.mikestanescu.com" />
      <meta property="og:site_name" content="mikestanescu.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@mikestanescu" />
    </Helmet>
      <div className="fixed inset-0 flex justify-center 0">
        <div className="flex w-full max-w-7xl ">
          <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20" />
        </div>
      </div>
      
      <div className="relative">
        <Header />
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
